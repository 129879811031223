@charset "utf-8";

@font-face {
    font-family: 'Noto Sans SC';
    font-style: normal;
    font-weight: 400;
    /*src: url(//file.valofe.com/Valofe_file/web/fonts/NotoSansSc/NotoSansSC-Regular.otf) format('opentype');*/
    src: url(//cdn.qijisoft.com/fonts/NotoSansSc/NotoSansSC-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Noto Sans SC';
    font-style: normal;
    font-weight: 500;
    src: url(//cdn.qijisoft.com/fonts/NotoSansSc/NotoSansSC-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Noto Sans SC';
    font-style: normal;
    font-weight: 700;
    src: url(//cdn.qijisoft.com/fonts/NotoSansSc/NotoSansSC-Bold.otf) format('opentype');
}


* {
    font-family:FontAwesome,'Noto Sans SC', sans-serif;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
}
/* * {
    font-family: FontAwesome, 'Noto Sans CJK KR', 'Noto Sans CJK SC', 'Noto Sans CJK SC',  'Trebuchet MS', 'Helvetica Neue',
    Microsoft JhengHei, PingFang TC, Microsoft YaHei, PingFang SC,
    'Malgun Gothic', Apple SD Gothic Neo, '애플 SD 산돌고딕 Neo',
    'M PLUS 1p', sans-serif;
    word-break: keep-all;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
} */