@charset "UTF-8";
@import "color";

#wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 0;
    padding-top: 60px;
}
#content {
    min-height: 900px;
}
.row-inner {
    width: 96%;
    margin: 0 auto;
}
.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    top: -1px;
    left: -1px;
    text-indent: -9999px;
}
//#content .row-w {width: 1000px !important;}

/**********************/
/* section 공통  -서브 */
#sub {
    section {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 90px;
        padding-top: 5.625rem;
    }
    section.vacancy {
        padding-top: 0;
    }
    section:last-of-type {
        padding-bottom: 5.625rem;
    }
    article {
        margin-top: 50px;
        margin-top: 3.125rem;
    }
    section + section article {
        margin-top: 0;
    }
}
.wrap-title .tit {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 14px;
    border-bottom: 3px solid $cl_point;
    text-align: left;
    letter-spacing: -1px;
    color: $cl_point;
    font-size: 60px;
    font-weight: 400;
    font-size: 3.75rem;
    font-weight: 700;
}
.wrap-title .tit .breadscrumb {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    right: 0;
    bottom: 14px;
    width: auto;
    font-weight: 400;
}
.wrap-title .tit .breadscrumb a small {
    font-size: inherit;
}
.wrap-title .sub-tit {
    display: block;
    width: 100%;
    line-height: 1.5;
    font-size: 28px;
    font-size: 1.75rem;
    font-weight: 400;
    letter-spacing: -1px;
    color: #ff7e00;
}

.breadscrumb a {
    display: inline-block;
    float: left;
}
.breadscrumb a,
.breadscrumb a:visited {
    font-size: 14px;
    color: #848383;
}
.breadscrumb a:after {
    display: inline-block;
    vertical-align: middle;
    content: ">";
    margin-left: 4px;
    margin-right: 7px;
    font-size: 14px;
}
.breadscrumb a:last-child:after {
    display: none;
}
.breadscrumb a:hover,
.breadscrumb a:focus,
.breadscrumb a.on {
    color: #ff7e00;
}

.wrap-title .sub-desc {
    display: block;
    margin-top: 5px;
    width: 100%;
    line-height: 1.5;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: -0.8px;
    color: #8c8c8c;
}
.wrap-title .sub-desc.tw span {
    line-height: 1.4;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 100;
    letter-spacing: -0.5px;
    margin-left: 0.25em;
}
.wrap-title .sub-desc.cn span {
    line-height: 1.4;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 100;
    letter-spacing: -0.5px;
    margin-left: 0.25em;
}
.wrap-title .sub-desc.tw {
    letter-spacing: -0.5px;
}
.wrap-title .sub-desc.cn {
    letter-spacing: -0.5px;
}

article .strong {
    margin-bottom: 20px;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    letter-spacing: -1px;
    color: #525252;
}
article .str-desc {
    display: block;
    width: 100%;
    line-height: 1.4;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 100;
    letter-spacing: -0.5px;
    color: #838383;
}
section .title {
    position: relative;
    overflow: hidden;
}
section h6.message {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.4;
    color: $cl_default;
    text-align: center;
}

/****** dl dt ******/
dl {
    width: 100%;
    padding: 20px 18px;
    text-align: center;
}
dl dt {
    margin-bottom: 10px;
    line-height: 1.2;
    color: #525252;
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: bold;
}
dl dd {
    line-height: 1.4;
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    color: #686868;
}

/****** row ******/
.row2 {
    width: 50%;
    float: left;
    position: relative;
    padding: 10px 0;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.4;
}
.row3 {
    width: 33.333333%;
    float: left;
    position: relative;
    padding: 10px 0;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.4;
}
.row4 {
    width: 25%;
    float: left;
    position: relative;
    padding: 10px 0;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.4;
}

/****** list ******/
.list3 {
    width: 100%;
    li {
        float: left;
        position: relative;
        padding: 1.75rem 1rem 3.75rem;
        margin-right: 12px;
        width: calc((100% - 24px) / 3);
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
    }
    &.bg li {
        background: #fafafa;
    }
}
.list4 {
    /* career/hr , career/intro */
    width: 100%;
    li {
        float: left;
        position: relative;
        padding-bottom: 10px;
        margin-right: 10px;
        width: calc((100% - 30px) / 4);
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
    }
}

/****** title ******/
.tit-type-lb {
    /* left-border 왼쪽에 보더 */
    position: relative;
    padding-left: 8px;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    color: $cl_default;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.25rem;
        display: inline-block;
        width: 4px;
        height: 18px;
        background: $cl_point;
    }
}

/****** button ******/
.wrap-btn.type-txt-l {
    text-align: left;
    .btn-site {
        width: 110px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }
}
.wrap-btn.type-pos-ab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%);
    margin: 0;
}
.btn-detail {
    width: 110px;
    width: 6.875rem;
    height: 32px;
    height: 2rem;
    line-height: 32px;
    line-height: 2rem;
}
.btn-apply,
.btn-result {
    padding: 0;
    width: 140px;
    width: 8.75rem;
    height: 42px;
    height: 2.625rem;
    line-height: 42px;
    line-height: 2.625rem;
}

/************************************************/
/******************* Company ********************/
/* company / intro */
.list3.miracle {
    display: flex;
}
.list3.miracle li:first-child dd {
    letter-spacing: -0.5px;
}
.company-info {
    table.basic tbody th img {
        margin-right: 6px;
    }
    .list3 li {
        padding: 1.75rem 0 3.75rem;
    }
}
.img-wrap {
    position: relative;
    margin-bottom: 1.5rem;
    img {
        max-width: 88%;
    }
}
.num.counter {
    position: absolute;
    left: 0;
    top: 54%;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 70px;
    font-size: 4.375rem;
    color: #000;
    text-shadow: 0px 0px 5px #fff;
}

/* company / history */
/*.color-green {color:$cl_point}*/
.history-tab {
    display: block;
    clear: both;
    width: 100%;
}
.history-tab ul {
    width: 100%;
    overflow: hidden;
}
.history-tab ul li {
    float: left;
    display: inline-block;
    width: 25%;
}
.history-tab ul li a,
.history-tab ul li a:visited {
    width: 100%;
    display: inline-block;
    text-align: center;
    border: none;
    color: #c1c1c1;
    padding: 16px;
    padding: 1rem;
    font-size: 24px;
    font-size: 1.5rem;
    -webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    border-left: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    border-top: 2px solid #878787;
    border-bottom: 1px solid #d7d7d7;
}
.history-tab ul li:first-child a {
    border-left: none;
}
.history-tab ul li a:hover,
.history-tab ul li a:focus,
.history-tab ul li a.on {
    color: #373737;
    background: #ffd030;
}
/*#history tbody {display:none;}*/
#history td a,
#history td a:visited {
    color: #6b6b6b;
    -webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
}
#history td a:hover,
#history td a:active {
    color: #6b6b6b;
    text-decoration: underline;
}

/* company / proposal */
/* input */
label.show {
    float: left;
    /*padding: 12px 13px 12px 40px;*/
    padding: 12px 13px 12px 3.5rem;
    width: 140px;
    width: 8.75rem;
    height: inherit;
    text-align: left;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    color: #353535;
}
.input-alert {
    display: none;
    padding-top: 48px;
    padding-left: 8.75rem;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #8c8c8b;
    &.on {
        display: block;
    }
}
.inputfile {
    /* input file attach */
    position: absolute;
    opacity: 0;
    visibility: hidden;
    & + label span.file-name {
        position: absolute;
        left: 0;
        padding: 10px 13px;
        padding-top: 0;
        margin-top: -10px;
        width: calc(100% - 90px);
        height: 40px;
        line-height: 40px;
        text-align: left;
        background: #fff;
        font-size: 14px;
        overflow: hidden;
        color: #a8a8a8;
    }

    & + label {
        position: relative;
        float: left;
        display: block;
        padding: 10px 13px;
        margin: 0;
        width: calc(100% - 140px);
        width: calc(100% - 8.75rem);
        height: 40px;
        min-width: inherit;
        max-width: 100%;
        background: #fafafa;
        border: 1px solid #ddd;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #8c8c8b;
        font-size: 14px;
        cursor: pointer;
        overflow: hidden;

        strong {
            position: absolute;
            right: 0;
            display: inline-block;
            padding: 10px 13px;
            padding-left: 10px;
            margin-top: -11px;
            width: 120px;
            width: 7.5rem;
            height: 40px;
            line-height: 20px;
            text-align: center;
            background-color: $cl_point;
            color: #fff;
            font-size: 14px;
            z-index: 1;

            span {
                font-weight: 400;
            }
        }
    }
}

/* tbl-row */
.tbl-row {
    padding: 8px 1.875rem;
    border-bottom: 2px solid #fff;
    background: #fafafa;
    &.proposal {
        padding: 8px 40px 8px 0px;
        padding: 8px 2.5rem 8px 0px;
    }
    .wrap-input {
        float: left;
        width: calc(100% - 140px);
        width: calc(100% - 8.75rem);
        font-size: 13px;
        color: #8c8c8b;
    }
    div.row.row3 {
        text-align: center;
    }
}
.tbl-row.agree {
    padding: 20px 2.5rem;
    font-size: 14px;

    input[type="checkbox"] {
        margin: 0 4px 0 0;
    }

    .textarea {
        background: #cacaca;
    }
    .wrap-input.agree {
        float: right;
        padding: 5px 13px 12px 40px;
        width: auto;
        label {
            color: #4d4d4d;
        }
    }
}
.btn-tbl-form {
    width: 110px;
    height: 40px;
    padding: 0;
    line-height: 40px;
}
.tbl-row input::placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row select::placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row textarea::placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row input:-ms-input-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row select::-ms-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row textarea::placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row input::-ms-input-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row select::-ms-input-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row textarea::placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row input::-moz-input-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row input::-moz-textarea-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row select::-moz-input-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row select::-moz-textarea-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row textarea::placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row input::-webkit-input-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row input::-webkit-textarea-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row select::-webkit-input-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row select::-webkit-textarea-placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}
.tbl-row textarea::placeholder {
    color: #6f6f6f !important;
    text-align: left;
    font-size: 13px !important;
}

/************************************************/
/******************** biz ***********************/
/* 소팅 네비 */
.sorting-nav {
    display: block;
    margin-top: 3rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #d5d4d4;
}
.sorting-nav h1 {
    width: 50%;
    float: left;
    font-size: 1.75rem;
}
.sorting-nav ul {
    float: right;
    display: inline-block;
    margin-top: -0.75rem;

    li {
        float: left;

        &:before {
            content: "|";
            display: inline-block;
            margin-left: 1rem;
            margin-right: 1rem;
            font-size: 14px;
            color: #68788e;
        }

        &:first-child:before {
            display: none;
        }

        a {
            display: inline-block;
            color: #68788e;
            font-size: 14px;
            font-weight: 500;
        }
        &.on a {
            color: #bdc2d2;
        }

        .fa {
            display: inline-block;
            color: #68788e;
            font-size: 14px;
            font-weight: 500;
        }
    }
}
.sorting-nav ul li a:hover,
.sorting-nav ul li.on a,
.sorting-nav ul li a:hover .fa,
.sorting-nav ul li.on a .fa {
    display: inline-block;
    color: #64d4f3; /*#bdc2d2;*/
    font-size: 14px;
    font-weight: 500;
}

/* wrap device + genre */
.wrap-device-all {
    float: right;
    padding: 0 16px 0 0;
    font-size: 14px;
    font-size: 0.875rem;
    color: #888888;
    .wrap-device {
        display: inline-block;
        margin-right: 5px;
        span {
            position: relative;
            margin: 0 8px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
/* 온라인 게임, 모바일 게임 리스트 */
ul.list-1 {
    width: 100%;
    overflow: hidden;

    li {
        position: relative;
        float: left;
        margin: 0 0 10px 0;
        padding: 24px;
        padding: 1.5rem;
        width: 100%;
        background: #fafafa;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    > li {
        display: none;
        opacity: 0;
        overflow: hidden;
        &.on {
            display: block;
            opacity: 1;
        }
    }
}
/* 게임 썸네일 이미지 */
ul.list-1.game li .pull-left {
    width: 30%;
    overflow: hidden;
    a {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            max-height: 190px;
            height: 100%;
            border: 1px solid #bbbbbb;
            -webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
            -moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
            -o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
            transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
        }
        &:hover img {
            transform: scale(1.1);
        }
    }
}
/* 게임 텍스트 */
ul.list-1.game li .pull-right {
    width: 70%;
    padding-left: 20px;
}
ul.list-1.game dl {
    margin-bottom: 15px;
    padding: 10px 0 0;
    text-align: center;
    dt {
        margin-bottom: 10px;
        padding-bottom: 1rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
        font-size: 18px;
        font-size: 1.125rem;
    }
    dd {
        margin-bottom: 10px;
        line-height: 1.7;
        text-align: left;
        letter-spacing: -0.2px;
        font-weight: 400;
        font-size: 15px;
        font-size: 0.9375rem;
        color: #888;
    }
}
ul.list-1.game h3 {
    color: #888;
    font-size: 14px;
}

/************************************************/
/******************* Career *********************/
/* hr */
.list3.hr {
    padding: 0;
    > li {
        padding: 0;
    }
}

.hr-qualification {
    margin-bottom: 1rem;
    .img-wrap img {
        width: 100%;
    }
    .tit {
        display: block;
        font-weight: bold;
        text-align: center;
        line-height: 1.2;
        color: #525252;
        font-size: 18px;
        font-size: 1.125rem;
    }
    .text {
        /* position: absolute; */
        display: block;
        padding: 10px 16px;
        text-align: center;
        line-height: 1.2;
        font-size: 18px;
        font-size: 1rem;
        font-weight: 400;
        color: #8c8c8c;
    }
}
.hr-summary {
    li {
        &:nth-child(1) dd {
            background: url(//cdn.qijisoft.com/miracle/images/sub/career/hr/hr-eval-min.png) right bottom
                no-repeat;
        }
        &:nth-child(2) dd {
            background: url(//cdn.qijisoft.com/miracle/images/sub/career/hr/hr-salary-min.png) right
                bottom no-repeat;
        }
        &:nth-child(3) dd {
            background: url(//cdn.qijisoft.com/miracle/images/sub/career/hr/hr-welfare-min.png) right
                bottom no-repeat;
        }
    }
    dt {
        height: inherit;
        padding-bottom: 0;
        margin-bottom: 20px;
        margin-bottom: 1.25rem;
        text-align: left;
        line-height: 1.2;
    }
    dd {
        width: 100%;
        height: 180px;
        padding: 20px;
        border: none;
        text-align: left;
        font-size: 14px;
        font-size: 0.875rem;
        color: #767676;
        border: 1px solid #ddd;
    }
    .dep2 {
        margin: 0;
        li {
            display: block;
            float: none;
            margin: 0;
            padding: 5px 0;
            padding: 0.3125rem 0;
            width: auto;
            text-align: left;
            line-height: 1.5;
            &.in2 {
                float: left;
                margin-right: 25px;
            }
        }
    }
}

/* job introduction */
.job-introduction {
    h4 {
        font-size: 24px;
        font-size: 1.5rem;
        img {
            margin-right: 0.5rem;
            vertical-align: sub;
        }
    }
}
.list4.job {
    li {
        padding: 0 28px 0 0;
        background: url(//cdn.qijisoft.com/miracle/images/sub/career/job/job-arrow.png) no-repeat center
            right 10px / auto;
        background-color: #f5f5f5;
        border-left: 5px solid rgba(#2f3136, 0.2);
        &:hover {
            border-left: 5px solid $cl_point;
            background-image: url(//cdn.qijisoft.com/miracle/images/sub/career/job/job-arrow-on.png);
            background-color: $cl_point_pale;
            a {
                transition: none;
                color: $cl_point;
            }
        }

        a {
            display: inline-block;
            padding: 1.5rem 1rem;
            width: 100%;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
#sub .recruit {
    background: none;
    h1 {
        margin-bottom: 1rem;
        font-size: 40px;
        font-size: 2.5rem;
        color: $cl_default;
    } /* tit-type3 */
}

/* career / notice & detail */
.job-notice,
.job-list {
    padding: 0;
    .tbl-row.th {
        background: #2f3136;
        color: #efefef;
        text-align: center;
    }
    label.show {
        color: inherit;
    }
}
.vacancy-form label.show {
    padding: 12px;
}
.vacancy .tbl-row {
    text-align: center;
    padding: 8px 30px;
    padding: 8px 1.875rem;

    .row4:nth-of-type(n + 3) {
        text-align: center;
    }
}

/* career / detail */
.op-txt .row p {
    line-height: 1.6;
    a {
        color: inherit;
    }
}
.detail .row3:last-of-type {
    text-align: center;
}

/* career / my application */
.list3.my {
    display: flex;
}
.apply-form.my .tbl-row {
    background: #2f3136;
    label.show {
        color: #fff;
    }
}
.apply dl dt {
    font-size: 18px;
    font-size: 1.125rem;
}

/* career / result */
.apply-result {
    .wrap-result {
        padding: 50px 16px;
        padding: 3.125rem 1rem;
        background: #f5f5f5;
    }
    ul li {
        margin: 0 auto;
        padding: 5rem 0 7rem;
        width: 410px;
        max-width: 96%;
        background: #fff;
    }
}

/************************************************/
/******************** 404 ***********************/
#sub section#notFound {
    position: relative;
    /*margin-top:60px;*/
    /*padding-top: 17rem;
    padding-top: 26vh;*/
    height: calc(100vh - 230px);
    text-align: center;
    color: #68788e;
    .row-w {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    h1 {
        font-size: 80px;
        font-size: 5rem;
    }
    p {
        margin: 2rem 0;
        line-height: 1.5;
        font-size: 18px;
        font-size: 1.125rem;

        span {
            //color:#ff7e00;
            color: #64d4f3;
        }
    }
    .btn {
        background: #68788e;
        &:hover {
            background: $cl_default;
        }
    }
}

@media screen and (max-width: 960px) {
    /* common 타이틀 */
    .wrap-title .tit {
        padding-bottom: 36px;
    }
    .wrap-title .tit .breadscrumb {
        bottom: 4px;
    }
    .breadscrumb a,
    .breadscrumb a:visited {
        font-size: 13px;
    }
    /* 회사소개 : 제휴 */
    .proposal .row3 {
        width: 100%;
        float: none;
        clear: both;
        min-height: 39px;
        margin-bottom: 14px;
    }
    .tbl-row .wrap-input {
        width: calc(100% - 115px);
        width: calc(100% - 8.8rem);
    }

    /* career / intro */
    .list4.job li {
        width: calc((100% - 10px) / 2);
        margin-bottom: 10px;
    }
    .list4.job li:nth-child(2n) {
        margin-right: 0;
    }

    /* career / notice */
    .vacancy-form label.show {
        padding: 12px 10px;
        width: 5.75rem;
    }
    .vacancy-form .tbl-row .wrap-input {
        width: calc(100% - 5.8rem);
    }
    .vacancy-form .tbl-row .wrap-input select {
        width: 80%;
    }
    .vacancy-form .row3 {
        width: 50%;
    }
    .vacancy .tbl-row .row4 {
        width: calc((100% - 150px) / 2);
    }
    .vacancy .tbl-row .row4:nth-of-type(2) {
        text-align: center;
    }
    .vacancy .tbl-row .row4:nth-of-type(3) {
        width: 60px;
    }
    .vacancy .tbl-row .row4:nth-of-type(4) {
        width: 90px;
    }

    /* 404 not found */
    #notFound h1 {
        font-size: 4rem;
    }
}

@media screen and (max-width: 840px) {
    /* career/apply/1 */
    .apply-form .tbl-row:nth-of-type(3) .row3 {
        width: 50%;
    }
}
@media screen and (max-width: 768px) {
    /* common 타이틀 */
    article .tit {
        font-size: 3.5rem;
    }
    article .sub-tit {
        font-size: 1.5rem;
    }
    article .sub-desc {
        font-size: 1.2rem;
    }

    /* biz / game */
    ul.list-1.game li .pull-left {
        position: absolute;
        left: 1.5rem;
        width: 110px;
        height: 130px;
    }
    ul.list-1.game li .pull-left a img {
        width: 300%;
    }
    ul.list-1.game li .pull-right {
        width: calc(100% - 110px - 1.5rem);
        padding-left: 0;
    }
    .qiji-game .wrap-btn {
        margin-top: 0;
    }
    .wrap-device-all {
        float: none;
        margin-top: 6px;
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    /* company / intro */
    .company-info table col:first-child {
        width: 120px;
    }

    /* company / intro */
    .miracle-is .list3 {
        display: inline-block;
    }
    .list3 li {
        width: calc((100% - 12px) / 2);
    }
    .list3 li:nth-child(2n) {
        margin-right: 0;
    }
    .list3 > li:last-child {
        /*display: none;*/
        margin-top: 12px;
        padding-bottom: 1.75rem;
        width: 100%;

        .img-wrap {
            display: inline-block;
            width: 50%;
            vertical-align: middle;
            margin-bottom: 0;
            img {
                width: 25vw;
                min-width: 104px;
            }
        }
        dl {
            display: inline-block;
            width: 50%;
            text-align: left;
            vertical-align: middle;
        }
    }

    .company-info table col:first-child {
        width: 100px;
    }
    .company-info .list3 li {
        padding: 1.75rem 0 0;
        margin-top: 0;

        img {
            width: 100%;
        }
    }

    /* career / intro */
    .vacancy-form .row3 {
        width: 100%;
    }
    .vacancy-form label.show {
        width: 8.75rem;
    }
    .vacancy-form .tbl-row .wrap-input {
        width: calc(100% - 8.8rem);
    }
    .vacancy-form .tbl-row .wrap-input select {
        width: 100%;
    }

    /* career / my */
    .list3.my {
        display: inline-block;
    }
    .list3.my > li:last-child dl {
        text-align: center;
    }

    /* career/apply/1 */
    .apply-form.my .tbl-row label.show {
        padding: 12px 13px 12px 0.5rem;
        width: 4.75rem;
    }
    .apply-form.my .tbl-row .wrap-input {
        width: calc(100% - 5.8rem);
    }
    .apply-form .tbl-row:nth-of-type(4) .row2 {
        width: 100%;
    }

    /* 404 not found */
    #notFound h1 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 480px) {
    /* common */
    .list4 li {
        width: calc((100% - 10px) / 2);
    }
    .list4 li:nth-child(2n) {
        margin-right: 0;
    }
    .proposal .tbl-row,
    .apply .tbl-row {
        padding-right: 1rem;
        padding-left: 0;
    }
    label.show {
        width: 7.75rem;
    }
    .tbl-row .wrap-input {
        width: calc(100% - 7.8rem);
    }

    /* compandy / intro */
    .miracle-is .list3 > li {
        margin-top: 12px;
        padding-bottom: 1.75rem;
        width: 100%;

        .img-wrap {
            display: inline-block;
            width: 50%;
            vertical-align: middle;
            margin-bottom: 0;
            img {
                width: 25vw;
                min-width: 104px;
            }
        }
        dl {
            display: inline-block;
            width: 50%;
            text-align: left;
            vertical-align: middle;
        }
    }

    /* company / history */
    .history-tab ul li a,
    .history-tab ul li a:visited {
        padding: 1rem 0.875rem;
        font-size: 0.875rem;
    }
    #history colgroup col:nth-of-type(1) {
        width: 56px;
    }
    #history colgroup col:nth-of-type(2) {
        width: 46px;
    }

    /* company / proposal */

    label.show {
        padding-left: 1.5rem;
    }
    .inputfile + label span.file-name {
        width: calc(100% - 5.5rem);
    }
    .inputfile + label strong {
        width: 5.5rem;
    }
    .tbl-row .wrap-input.agree {
        margin-top: 0.5rem;
    }

    /* career / hr */
    .list3.hr > li:last-child {
        margin-top: 2rem;
    }
    .list3.hr > li:last-child dl {
        width: 100%;
    }

    /* career / notice */
    /*.vacancy-form .row3 {width:100%;}
    .vacancy-form label.show {width: 8.75rem}
    .vacancy-form .tbl-row .wrap-input {width:calc(100% - 8.8rem);}
    .vacancy-form .tbl-row .wrap-input select {width:100%;}*/
    .vacancy .tbl-row .row4 {
        width: calc((100% - 150px));
    }
    .vacancy .tbl-row .row4:nth-of-type(2) {
        display: none;
    }

    /* career / detail */
    .detail .tbl-row {
        padding: 8px;
    }
    .detail .row3 {
        padding: 5px;
    }

    /* career / apply */
    .apply .row2 {
        width: 100%;
    }
    .apply-form .tbl-row:nth-of-type(3) .row3 {
        width: 100%;
    }

    /* career / my */
    .apply-form.my .tbl-row label.show {
        padding: 12px 13px 12px 1.5rem;
        width: 5.75rem;
    }
}

@media screen and (max-width: 414px) {
    /* biz / game */
    ul.list-1.game li .pull-left {
        height: 110px;
    }
    ul.list-1.game li .pull-right {
        width: 100%;
        margin-top: 4.4rem;
    }
    ul.list-1.game dl dt {
        margin-left: calc(110px + 1.5rem);
    }

    /* career / notice */
    .vacancy-form label.show {
        width: 7.75rem;
    }
    .vacancy-form .tbl-row .wrap-input {
        width: calc(100% - 7.8rem);
    }
}
