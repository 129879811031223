@charset "UTF-8";
/* 메인 배너 스와이퍼 */

.visual {
    .sliderContainer {
        position: relative;
        .swiper-container {
            width: 100%;
            /*height: 34rem;*/

            article {           // text로 들어간다면
                z-index: 10;
                position: absolute;
                top: 6rem;
                left: 8%;
                word-break: normal;
                text-align: left;
                font-weight: 700;
                text-shadow: 0 0 2px rgba(127, 99, 54, 0.9);
                color: #fff;

                top: 64px;
                top: 2.5em;
                left: calc(50% - 720px);
                font-size: 1.75rem;

                img.logo {
                    height: 5vw;
                    width: auto;
                }

                .tit {
                    display: inline-block;
                    box-sizing: border-box;
                    margin-top: 48px;
                    margin-top: 1rem;
                    padding-right: 0;
                    width: 100%;
                    color: #fff;
                    font-size: 2rem;
                    line-height: 1.4;
                    font-weight: 700;
                    word-break: keep-all;
                }

                .sub {
                    display: inline-block;
                    box-sizing: border-box;
                    margin-top: 16px;
                    margin-top: 0.5em;
                    padding-right: 0;
                    width: 100%;
                    line-height: 1.4;
                    color: #fff;
                    font-size: 20px;
                    font-size: 1em;
                    font-weight: 400;
                }
            }
        }
    }

}

//bullet
.visual .swiper-container-horizontal > .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1rem;
    left: 0;
    /*max-width: 1440px;*/
    width: 100%;
}
.visual .swiper-pagination-bullet {
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: 10px;
    height:10px;
    border:2px solid #fff;
    background:none;
    vertical-align: middle;

    &.swiper-pagination-bullet-active {
        width:14px; height:14px;
        background:#fff;
    }
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {margin: 0 5px;}

// contents
.visual .swiper-slide {
    cursor: pointer;
    img {width:100%;}
    .img {
        height: 100%;
        width: 100%;
        max-height: 584.77px;
    }
}

// progressbar
.visual .sliderContainer .progressBarContainer {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 50%;
    border-bottom: none;
    text-align: center;
    .bar {
        display: inline-block;
        position: relative;
        width: calc(100% / 6 - 4px);
        margin-bottom: -3px;
        padding: 0;
        cursor: pointer;
        color: #fff;
        margin-left: 4px;
        &:last-child {
            margin-right: 0;
        }

        span.progressBar {
            position: relative;
            display: block;
            width: 100%;
            height: 3em;
            background-color: rgba(255, 255, 255, 0.4);
            transition: all 0.5s ease-in;
            &.on {
                background-color: rgba(255, 255, 255, 0.95);
            }
        }
        &:hover span.progressBar {
            background-color: rgba(255, 255, 255, 0.95);
        }
    }

    .bar .tit {
        z-index: 1;
        position: absolute;
        box-sizing: border-box;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        line-height: inherit;
        text-align: center;
        vertical-align: middle;
        display: table !important;

        span {
            position: relative;
            display: table-cell !important;
            color: #000;
            vertical-align: middle;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
        }
    }
}

// btn-more ??
.visual .sliderContainer .btn-more {
    display: inline-block;
    position: relative;
    margin-top: 37px;
    margin-top: 1.5em;
    height: 2em;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 1em;
    line-height: 2;
    color: #fff;
    text-align: center;
    /*background: url(//cdn.qijisoft.com/miracle/images/main/visual/btn_visual_more_off.png) 0 0 no-repeat / 100% 100%;*/

    &:hover {
        /*background: url(//cdn.qijisoft.com/miracle/images/main/visual/btn_visual_more_on.png) 0 0 no-repeat /100% 100%;*/
    }
}

// swiper arrow
.visual .swiper-button-prev:after,
.visual .swiper-container-rtl .swiper-button-next:after {
    content: '';
}

@media screen and (max-width: 1533px) {
    .visual .sliderContainer .progressBarContainer {width: 1281px;left: 50%;margin-left: -640px;}
}
@media screen and (max-width: 1440px) {
    .visual .swiper-pagination-fraction,
    .visual .swiper-pagination-custom,
    .visual .swiper-container-horizontal > .swiper-pagination-bullets {width: 80%;max-width: 80%;}
}

@media screen and (max-width: 1280px) {
    .visual .sliderContainer .swiper-container article {width: 96%;}
    .visual .sliderContainer .progressBarContainer {width: 96%;margin-left: -48%;}
    .visual .sliderContainer article img.logo {height: 8vw;}
    .visual .sliderContainer article .tit {font-size: 2rem;}
    .visual .sliderContainer .btn-more {clear: both;line-height: 1em;font-size: 0.8em;width: auto;height: 1em;padding: 0.5em 2em;}
}

@media screen and (max-width: 1200px) {
    .row-w {max-width: 1100px !important;}
    .visual .sliderContainer .swiper-container article {width: calc(100% - 32px);}
    .visual .sliderContainer .progressBarContainer {width: calc(100% - 32px);margin-left: calc(-50% + 20px);}
}
@media screen and (max-width: 1024px) {
    .visual .sliderContainer .swiper-container { height:240px;}
    .visual .swiper-slide img {height:100%;}

    /*.visual .sliderContainer .swiper-container {height: 33.75rem;}*/
    .visual .swiper-pagination-fraction,
    .visual .swiper-pagination-custom,
    .visual .swiper-container-horizontal > .swiper-pagination-bullets {width: 100%;max-width: 100%;}
    .visual .sliderContainer article .tit {line-height: 1.3;}
    .visual .sliderContainer .progressBarContainer .bar {background: rgba(0, 0, 0, 0.15);}
    .visual .sliderContainer .progressBarContainer .bar span.progressBar {height: 3em;}
    .visual .sliderContainer .progressBarContainer {width: 100%;margin-left: 0;left: 0;bottom: calc(-3em - 3px);border-bottom: 4px solid #000;        border-top: 4px solid #000;background: #000;}

    .visual .sliderContainer .swiper-pagination {display: block;width: 100%;max-width: 100%;}

    .visual .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:nth-child(5),
    .visual .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:nth-child(6),
    .visual .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:nth-child(7),
    .visual .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullet:nth-child(8) {
        margin-top: 5px;
    }
}
@media screen and (max-width: 812px) {
    .visual .row-w {width: 100% !important; }
}
@media screen and (max-width: 768px) {
    /*.visual .sliderContainer .swiper-container { height: !*28.35rem*! 28.75rem;}*/
    .visual .sliderContainer .progressBarContainer .bar .progressBar.on {background: #fff;}
    .visual .sliderContainer article img.logo {height: 5rem;}
    .visual .sliderContainer .swiper-container article {width: 70%;left: 7%;}
    .visual .sliderContainer .img {width: auto;}
}
@media screen and (max-width: 586px) {
    .visual .sliderContainer article .tit {width: 70%;}
}
