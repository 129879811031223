@charset "UTF-8";
@import '../../asset/css/color';

* {font-size: inherit;}
//common
#main {
    section:not(:first-child) {
        margin:0;
        padding:3.5rem 0;
    }
}
#content {
    color : $cl_default;
}
.row-w {position:relative;}
.wrap-btn {margin:20px 0;}
.common-btn {display: inline-block; width: 100%; height: 100%;}
.btn-more-new {
    position: absolute;
    display: inline-block;
    /* margin: 4rem auto; */
    width: 31px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #36c9f2;
    text-align: center;
    color: #36c9f2;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;

    span {
        vertical-align: middle;
        font-size:14px;
    }

    &:hover {
        width: 96px;
        border-radius: 30px;
        transition: all 0.4s;

        span {
        }
    }
}

// main
.whatwedo-new .cont{
    width:100%;text-align:center;
    ul{
        display:inline-block;
        margin:0 auto;
        width:100%;
        overflow:hidden;

        li{
            float:left;
            position:relative;
            box-sizing:border-box;
            padding: calc(2.5% + 32px) calc(2.5% + 43px);
            height:460px; height:28.75rem; width:33.3333%;
            background:transparent;
            border-radius:8px; border-right:1px solid #d5dbdf;

            &:last-of-type {border:none;}

            &:nth-child(1) {
                .img{background:rgba(255,255,255,0.25) url(http://www.handy.dev.valofe.com/static/images/main2021/what-img1-new.png)no-repeat center / 100% auto;}
                dt {background:#f6bb43;}
            }
            &:nth-child(2) {
                .img{background:rgba(255,255,255,0.25) url(http://www.handy.dev.valofe.com/static/images/main2021/what-img2-new.png)no-repeat center / 100% auto;}
                dt {background: #6d9d92}
            }
            &:nth-child(3) {
                .img{background:rgba(255,255,255,0.25) url(http://www.handy.dev.valofe.com/static/images/main2021/what-img3-new.png)no-repeat center / 100% auto;}
                dt {background: #f7707d}
            }
        }

        .img{
            position:relative;
            display:inline-block;
            width:71%; height:50%; min-width:180px; min-height:146px;
            &:lang(en), &:lang(jp) {
                width:71%; height:50%;
                min-width:130px; min-height:130px;
            }
        }
        .line {
            display:none;
            padding:0;
            width:60%; height:1px;
            background:#4f4f4f;
        }
    }
    .btn-more {bottom:0; left:50%; transform: translateX(-50%);}
}
.whatwedo-new dl{
    margin-top:15px;
    width:100%;

    dt {
        padding:6px;
        width:100%;
        line-height:1.2;
        background:#23b2da; border-radius:20px;
        font-size:22px;font-size:1.375rem;
        color:#fff;

        span {
            font-size:18px; font-size:1.125rem; font-weight:bold;
        }
    }
    dd {
        margin-top:15px;
        width:100%;
        line-height:1.2;
        font-size:16px; font-size:1rem;
        color:#777777;

        &:lang(jp) {font-size:15px;line-height:1.5;font-weight:bold;}
    }
}

// main what we do
.whatwedo{
    background:#f5f5f5;
    .tit {
        margin:1.25rem 0 4.6rem;
        text-align: center;
        color:$cl_default;
        font-size:50px; font-size:3.125rem;
        text-transform: uppercase;
        img {
            margin-right:14px; margin-right: 0.875rem;
            vertical-align: bottom;
            width:46px; width:2.875rem;
        }
    }
    .cont {
        width:100%;
        text-align:center;

        ul {
            display: flex;  /* li height 동일하게 맞추기 */
            margin:0 auto;
            width:100%;

            li {
                float:left;
                position:relative;
                margin-left:2.728%;margin-right:2.728%;
                /*height:390px;*/
                width:26.365%; /*max-width: 290px;*/
                box-sizing:border-box;
                background:transparent;
                border-radius:8px;

                &:nth-child(1) {
                    a {background: linear-gradient(to bottom, #fee0ad, #bbc5ae);}
                    .img{ background:rgba(255,255,255,0.25) url(http://cdn.qijisoft.com/images/main/what-img1-min.png) no-repeat center / 100%;}
                }
                &:nth-child(2) {
                    a {background: linear-gradient(to bottom, #edf6f9, #659eb2);}
                    .img{background:rgba(255,255,255,0.25) url(http://cdn.qijisoft.com/images/main/what-img2-min.png) no-repeat center / 100%;}
                }
                &:nth-child(3) {
                    a {background: linear-gradient(to bottom, #ffe8e2, #edb6aa, #76677d);}
                    .img{background:rgba(255,255,255,0.25) url(http://cdn.qijisoft.com/images/main/what-img3-min.png) no-repeat center / 100%;}
                }

                /*&:after {
                    content:'';
                    display: inline-block;
                    padding-bottom:120%;
                }*/

                a, a:visited{
                    display:inline-block;
                    padding:32px 24px 80px; padding:2rem 1.5rem 5rem;
                    width:100%; height:100%;
                    text-align:center;
                    border-radius:8px;
                    -webkit-transition: all .75s cubic-bezier(.23,1,.32,1);
                    -moz-transition: all .75s cubic-bezier(.23,1,.32,1);
                    -o-transition: all .75s cubic-bezier(.23,1,.32,1);
                    transition: all .75s cubic-bezier(.23,1,.32,1);
                }

                .img {
                    position:relative;
                    display:inline-block;
                    margin-bottom:20px;
                    width:69.422%;
                    border-radius:50%;

                    &:after {
                        content:'';
                        display: inline-block;
                        padding-bottom: 100%;
                    }
                }

                .line{display:none;width:60%;padding:0;height:1px;background:#4f4f4f;}
            }
        }
    }
}
.whatwedo dl {
    margin-top:15px;
    width:100%;

    dt {
        position:relative;
        width:100%;
        line-height:1.2;
        font-size:22px;font-size:1.375rem;
        color:#000;

        span{font-size:22px;font-size:1.375rem;}
    }
    dd {
        position:absolute; bottom:2rem; left:0;
        margin-top:32px; margin-top:2rem;
        width:100%;
        line-height:1.2;
        font-size:16px;font-size:1rem;
        color:#fff;

        &:lang(jp) {
            line-height:1.5;
            font-size:15px;
            font-weight:bold;
        }
    }
}
.whatwedo a:hover dd, .whatwedo a:focus dd, .whatwedo a:active dd{display:none;}
.whatwedo a:hover dt:after{content:'See More';display:block;position: absolute;top: 100%;left: 0;margin-top:27px;width:100%;height:24px;
    font-weight:bold;font-size:16px;color:#434a54;}
/*.whatwedo li:nth-child(2) a:hover dt:after {margin-bottom:19px;}*/


// main recruit
.recruit{
    background:url(https://www.valofe.com/static/images/main/bg-job-sky.jpg) repeat-x top right 0 / 1920px auto;
    animation: aniBgCloud 30s 0s infinite linear;
    /*background:#c9ceb7;*/
    padding-bottom:250px;
    .row-w {/*z-index:2;*/position:relative;}
    .tit {
        text-align: left;
        font-size:50px;
        position: relative;
        margin: 28px 0 36px;
        .btn-more {
            position: absolute;
            right: 0; top: 50%;
            color: #abb1bd;
            font-size: 18px;
            transform: translateY(-50%);
        }
    }
    ul {
        font-size:0;
        letter-spacing: -5px;
    }
    ul li {
        float:left;
        margin-left:50px;
        margin-bottom:20px;
        width:calc(33.333333% - 33.33333px);

        display:inline;
        zoom:1;
        letter-spacing: initial;
        vertical-align: top;


        &:nth-child(2) {max-height:121px;}
        &:nth-child(3n + 1) {margin-left:0;}

        a {
            position:relative;
            display:block;
            padding:30px;
            padding: 1.875rem 1rem;
            width:100%; height:auto;
            background:#efefef;
            box-shadow:0 0 3px rgba(0,0,0,.3);

            &:hover{
                background:#eae4d9;

                .btn-job-arr {background:url(http://cdn.qijisoft.com/images/main/btn-job-on-min.png) 0 0 no-repeat;}
                .img-wrap img {opacity: 0.65;}
            }
        }
    }
    .text { // dl
        display:inline-block;
        margin-left:90px;
        width:calc(100% - 90px);

        dt {
            line-height:1.2;
            font-size:18px;
            font-weight:bold;
            color:$cl_default;
            word-break: keep-all;
        }

        dd {
            margin-top:8px;
            line-height:1.4;
            font-size:14px;
            font-weight:bold;
            color:#505050;
        }
    }

    .img-wrap{
        position:absolute;
        top:30px; top:1.875rem; left:20px;
        display:block;
        width:70px; height:80px;
    }

    .btn-job-arr {
        position:absolute;
        right:0; bottom:0;
        display:inline-block;
        width:41px; height:41px;
        background:url(http://cdn.qijisoft.com/images/main/btn-job-min.png) 0 0 no-repeat;
    }
}
@keyframes aniBgCloud {
    0% {background-position:top right -1920px;}
    100% {background-position:top right 1920px ;}
}

/* type write css */
.type-jobs-wrap {position:relative;}
.type-write{
    z-index:0;
    width:100%; min-height:176px;
    clear: both;
}
.line-1{
    position: relative;
    display:inline-block;
    transform: translateY(50%);
    margin: 30px 30px 30px 0;
    padding-right:16px;
    width: 50%; height:46px;
    text-align:left;
    line-height:40px;
    border-right: 2px solid #666;
    white-space: nowrap;
    font-size: 32px;
    overflow: hidden;
    color:#333;
}

/* Animation */
.anim-typewriter{
    animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
    from{width: 0;}
    to{width: 50%;}
}
@keyframes blinkTextCursor{
    from{border-right-color: rgba(0,0,0,.75);}
    to{border-right-color: transparent;}
}

.join-bar-en{
    position:absolute;
    right:0; top: 0;
    width:100%;min-height:176px;
    background:url(https://www.valofe.com/static/images/main/job-bar-en.png) no-repeat right top;
    background-size: 358px 176px;
    z-index:0;
}
.job-key {
    position: absolute;
    top: 60px; right: 0;
    display: inline-block;
    margin-right: 190px;
    width: 58px; height: 58px;
    background: url(https://www.valofe.com/static/images/main/job-key.png) center center no-repeat;
    -ms-animation-name: keyrotate;
    -moz-animation-name: keyrotate;
    -o-animation-name: keyrotate;
    -webkit-animation-name: keyrotate;
    animation-name: keyrotate;
    animation-duration: 5s;
    -webkit-animation-duration: 5s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
@keyframes keyrotate {
    from {
        opacity: 1;
        transform: rotate(0deg);
    }
    to {
        opacity: 1;
        transform: rotate(360deg);
    }
}
@-webkit-keyframes keyrotate {
    from {

        opacity: 1;
        transform: rotate(0deg);
    }
    to {
        opacity: 1;
        transform: rotate(360deg);
    }
}



/* hot position */
#main .hot-position .tit {
    position: absolute;
    top:0; left:0;
    margin:0;
    font-size:30px;
    text-align: left;
    text-transform: none;
}
.hot-position {
    background:url(http://cdn.qijisoft.com/images/main/chr-position-min.png)no-repeat left bottom / 32vw auto;
    background-color: #f7f7f7;
    article {
        float: right;
        width: 100%;
        max-width:770px;
    }
    article > div {
        width:44%;
        margin:0 3%;
        &:nth-child(2) {
            margin-right:0;
        }
    }
    .sub-tit {
        position:relative;
        padding-left:10px;
        border-left: 4px solid $cl_point;
        font-size:20px;
       /* font-size:1.25rem;*/
        font-weight:bold;
        color: $cl_default;
    }

    ul {margin-top: 1rem;}

    li {
        border-bottom:1px solid rgba(#2c2c2c, 0.1);
        font-size:14px;
        font-size:0.875rem;
        a {
            float:left;
            padding:12px 0 12px 12px;
            width: 100%;
            height: 100%;
            max-width: calc(100% - 100px);
        }
        .date {
            float: right;
            padding: 12px;
        }

        &:hover {
            background:#eae4d9;
        }
    }

    .btn-more {
        position:absolute;
        right:0; bottom:0;
        display: inline-block;
        width:58px;
        height:26px;
        line-height:26px;
        /*height: 1.625rem;
        line-height: 1.625rem;*/
        text-align: center;
        background: $cl_point;
        border-radius:12px;
        color:#fff;
        font-size:12px;
        font-weight: 400;
        &:hover {
            background: $cl_default;
            color:$cl_point;
        }
    }
}


/* partner proposal */
.partner {
    background:url(http://cdn.qijisoft.com/images/main/bg-partner-min.jpg)no-repeat center / cover;
    text-align: center;
    .tit {
        margin: 1.25rem 0 4.6rem;
        text-align: center;
        color: $cl_default;
        font-size: 50px;
        font-size: 3.125rem;
    }
    p {
        font-size:18px; font-size:1.125rem;
    }
    ul {
        width:430px; width:26.875rem;
        max-width:430px;
        margin:2rem auto 0;
        li {
            float:left;
            position:relative;
            margin-right: calc(1rem + 5%);
            width: calc( (90% - 2rem) / 3);
            &:last-child {
                margin-right:0;
            }
            &:after {
                content:'';
                position:absolute;
                display: inline-block;
                right: calc(-5% - 1rem);
                top: 50%;
                transform: translate(25%, -50%);
                width:1rem; height:1rem;
                background: url(http://cdn.qijisoft.com/images/main/small-chevron-right-min.png) no-repeat center;
            }
            &:last-child:after {
                content:none;
            }
        }
        dt img {
            width: 88%;
        }
        dd {
            margin-top:0.875rem;
            color: $cl_default;
            font-size:14px;
            font-size:0.875rem;
            font-weight:bold;
        }
    }
    .btn-partner {
        width: 218px;
        height:58px;
        line-height:58px;
        background: $cl_point;
        color:#fff;
        &:hover {
            background: $cl_default;
            color:$cl_point;
        }
    }
}


@media screen and (max-width:1024px) {
    /* 인재채용 */
    .recruit ul li { margin-bottom: 15px; margin-left: 15px; width: calc(33.333333% - 10px);}
    .recruit .img-wrap {left:16px;}
    .line-1{font-size: 22px; width: 50%;}

    /* 핫 포지션 */
    .hot-position {background-image:none;}
    .hot-position article {max-width: 100%;}
    .hot-position article > div:nth-child(2) {margin-right:3%;}
    #main .hot-position .tit {position: relative; margin-bottom: 2rem; text-align: center;}

}
@media screen and (max-width:960px){
    /* 인재채용 */
    .type-write{margin-top:140px;text-align:center;min-height:auto;}
    .join-bar-en{ background: url(https://www.valofe.com/static/images/main/job-bar-en.png) no-repeat center top; }
    .job-key{right: 50%;margin-right: 11px;}
    .line-1{font-size: 17px; margin: 30px auto; clear:both; text-align:center;}
    @keyframes typewriter{
        from{width: 0;}
        to{width: 320px;}
    }
}
@media screen and (max-width:900px) {
    /* 인재 채용 */
    .recruit .text dd{font-size:13px;}
    .recruit ul li{width:calc(50% - 5px);margin-left:10px;}
    .recruit ul li:nth-child(3n + 1){margin-left:10px;}
    .recruit ul li:nth-child(2n + 1){margin-left:0;}
    .recruit ul li:nth-child(2) {max-height:none;}
}
@media screen and (max-width:860px){
    .tit-wrap img{bottom:2rem}
}
@media screen and (max-width:640px) {
    .whatwedo .cont ul {display:inline-block;}
    .whatwedo .cont ul li {width:100%;height:auto;float:none;margin: 0;margin-top:10px;}

    .whatwedo .cont ul li a, .whatwedo .cont ul li a:visited {padding: 1.5rem; border-radius:0;background:#fff;}
    .whatwedo .cont ul li:nth-child(1) a{background:#fff;}
    .whatwedo .cont ul li:nth-child(2) a{background:#fff;}
    .whatwedo .cont ul li:nth-child(3) a{background:#fff;}

    .whatwedo .cont ul li .img {float:left;width:120px;	border-radius:0;margin-bottom: 0;}
    .whatwedo .cont ul li:nth-child(1) .img {background: #f2c200 url(http://cdn.qijisoft.com/images/main/what-img1-min.png) center no-repeat; background-size:80%;}
    .whatwedo .cont ul li:nth-child(2) .img {background: #88b9a8 url(http://cdn.qijisoft.com/images/main/what-img2-min.png) center no-repeat; background-size:80%;}
    .whatwedo .cont ul li:nth-child(3) .img {background: #f68595 url(http://cdn.qijisoft.com/images/main/what-img3-min.png) center no-repeat; background-size:80%;}

    .whatwedo dl {float:right; width:calc(100% - 140px); margin-left:20px; padding-right:20px;  padding-top: 0.5rem;}
    .whatwedo dl dd {position:relative;bottom:auto;left:auto;margin-top:1rem;padding-top:10px;border-top:1px solid #9f9f9f;}
    .whatwedo dl dt, .whatwedo dl dd{ text-align: left;color:#666;}
    .whatwedo li:last-child dl dd:lang(jp) {padding-top:0; margin-top:14px;}
    .whatwedo li .line{display:none;}

    .recruit ul li {display:inline-block; float:none;}
    .recruit .text dt {font-size:16px;}
    /*.recruit ul li a .img-wrap img {width:60px;}
    .recruit .text {margin-left:80px;width: calc(100% - 80px);}*/

    /* hot position */
    .hot-position article > div {margin:0;width: 48%;}
    .hot-position article > div:nth-child(2) {margin-right:0;}

}

@media screen and (max-width:480px){
    /* 인재 채용 */
    .recruit ul li{margin-left:0; width:100%;}
    .recruit ul li:nth-child(2n + 1), .recruit ul li:nth-child(3n + 1){margin-left:0;}
    .recruit ul li a{padding:15px; min-height:82px;}
    .recruit .img-wrap{top: 15px;left:15px;height:45px;}
    .recruit .img-wrap img{height:100%;}

    .recruit .text{margin-left:50px;width:calc(100% - 50px);}
    .recruit .text dt{width:50%;margin-top:6px;}
    .recruit .text dd{position: absolute;width: 50%;margin-top: 12px;top: 10px;right: 0;text-align: center;}

    @keyframes typewriter{
        from{width: 0;}
        to{width: 100%;}
    }
}
@media screen and (max-width:414px) {
    .whatwedo .cont ul li .img {width:100px;}
    .whatwedo dl {width: calc( 100% - 120px);padding-top:0; margin-top:0;}

    /* hot position */
    .hot-position article > div {margin-bottom:3rem; width: 100%;}
    .join-bar-en {background-size: 100% auto;}
}