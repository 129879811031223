@charset "UTF-8";
#util{
    display: none;
    position:fixed;
    z-index:2;
    top:0;left:0;
    width:100%;
    height:auto;
    background: #1D1A1B;
    color:#fff;
    padding:10px 0;
    text-align: right;
    .row-w{
        margin:0 auto;
        width:100%;
        max-width:1000px;
        overflow:hidden;
        select.sel{
            display: inline-block;
            vertical-align:middle;
            width: 120px;
            padding: 7px 12px 7px 1rem;
            font-size: 10px;
            color: #fff;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            &:focus{outline: none;box-shadow: none;}
        }
    }

}
#header {
    h1 a , li a {display: inline-block; width:100%; height:100%; color:inherit;}

    position:fixed;
    top:0px; left:0;
    width:100%; /*height:60px;*/
    background-image:linear-gradient(to bottom, #222831 60px, rgba(0,0,0,0.6) 60px);
    overflow: hidden;
    z-index: 2;

    #gnb {
        position:relative;
    }
    #logo {
        /*position:absolute; left:0; top:0;*/
        float:left;
        font-size:20px; font-size:1.25rem;
        line-height:60px;
        color:#fff;
        img {
            vertical-align: middle;
            height: 34px;
            margin-top: -6px;
        }
    }
    .gnb {
        /* position:absolute; right:0; top:0;*/
        float:right;
        margin-left:160px;
        height:60px;
        text-align: center;
        font-size:14px; font-size:0.875rem;
        color:#fff;
        > li {
            float:left;
            position:relative;
            width:125px;
            line-height:60px;
            font-weight:400;
            &:hover {
                // color: #ff7d00;
                &:after {
                    content: '';
                    position: absolute;
                    top:60px; left:0;
                    width:100%; height:3px;
                    background: #ff7d00;
                }
            }

        }
        ul {
            padding:0.5rem 0 ;
        }
        li li {
            line-height: 50px;
            color:#fff;
            font-weight:300;
            &:hover {
                color: #ff7d00;
                text-decoration: underline;
            }
        }
        li a {
            &:hover {
                color: #ff7d00;
            }
        }
    }

}
#mobileHeader {display:none;transition: right 0.4s;}
#mobileGnb {display:none;}
#mobileGnb.on {right:0;transition: right 0.4s;}

/* js에 따른 아코디언 상태 */
.nav-li > ul.on {
    display: block;
}



@media screen and (max-width:1024px){
    #header{display:none;}
    #mobileHeader {
        position:fixed;
        position:absolute;top:0;right:0;
        display:block;
        width:100%;height:60px;
        box-sizing:border-box;
        z-index:8800;
        background:#0f1c33;
        border-bottom:1px solid #5a6371;
        color: #fff;

        #crtMenu {position:absolute;display:inline-block;width:auto;
            left:0.5rem;
            text-align:center;
            line-height:60px;
            font-size:16px;
            font-weight:bold;
            color:#fff;
            cursor:pointer;
            -webkit-transition: all .75s cubic-bezier(.23,1,.32,1);
            -moz-transition: all .75s cubic-bezier(.23,1,.32,1);
            -o-transition: all .75s cubic-bezier(.23,1,.32,1);
            transition: all .75s cubic-bezier(.23,1,.32,1);

            #back, #crt{color:#fff;}
            .fa-angle-left{margin-right:8px;margin-top:-4px;font-size: 24px;vertical-align:middle;}
        }

        #qiji{
            position:absolute;
            left:50%;
            display:inline-block;
            margin-left:-80px;
            width:160px;
            text-align:center;
            line-height:60px;
            font-size:16px;
            font-weight:bold;
            color:#fff;

            img{display:inline-block;margin-top:-2px;height:30px;vertical-align:middle;}
        }

        #btnMoblieGnb {
            position:absolute;right:0.5rem;top:23px;
            display: inline-block;width: 30px;height: 30px;
            text-align:right;
            color:#fff;font-size:16px;

            // &:hover {color:#131313;}
        }
    }
    .shadow {
        position: fixed; top:0; left:0;
        width: 100%; height:100%;
        background:rgba(0,0,0,0.5);
        z-index:2;
    }

    /* 사이드 메뉴 */
    #mobileGnb {
        position:absolute;
        display:block;
        top:0; right: -600px;
        height:100%;
        font-size:16px; font-size:1rem;
        opacity:1;

        .side-menu {
            position:absolute;
            right:0px;top:0px;
            display:block;
            width: 300px !important;
            height:100%;
            overflow-y:auto;
            z-index:9999;
            box-sizing: border-box;
            background:#1a1e30;
            box-shadow:0px 3px 3px rgba(0,0,0,0.15);

            h1.tit-service{overflow:hidden;box-sizing:border-box;width:100%;height:101px;padding:20px 22px;/*border-bottom:1px solid #efefef;*/background:#0f1c33;}
            h1.tit-service a .mb-logo {margin-top: 14px;height: 35px;line-height: 50px;}
            h1.tit-service a.btn-mb-close{float:right;display:inline-block;margin-top:23px;width:15px;height:15px;background:url(//www.valofe.com/static/images/mb/btn-mb-close.png) 0 0 no-repeat;}
        }
    }

}