@charset "utf-8";
/* valofe CSS */
* {
	box-sizing: border-box;
}
html {
	font-size: 16px;
}
body {
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
}

/* CSS RESET 초기화 */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: baseline;
	background: transparent;
}
h1 {
	font-style: normal;
	font-weight: 700;
}
html {
	font-size: 16px;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

nav ul,
li {
	list-style: none;
}

a {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	background: transparent;
	text-decoration: none;
}

img {
	object-fit: cover;
	vertical-align: top;
}

hr {
	display: block;
	height: 2px;
	border: 0;
	margin: 0;
	padding: 0;
}

#wrapper {
	width: 100%;
	overflow: hidden;
	position: relative;
	margin: 0;
	padding: 0;
}
.skip-navi a {
	width: 1px;
	height: 1px;
	position: absolute;
	top: -1px;
	left: -1px;
	text-indent: -9999px;
}
.skip-navi a {
	color: inherit;
}
.skip-navi a:focus,
.skip-navi a:active {
	position: absolute;
	top: 0;
	padding-top: 0.5em;
	width: 100%;
	height: 2em;
	z-index: 999;
	text-align: center;
	color: #fff;
	background: #333;
	overflow: visible;
	clip: unset;
	text-indent: 0;
}
.hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	top: -1px;
	left: -1px;
	text-indent: -9999px;
}
.clearfix {
	clear: both;
}
.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}
.clearfix:after {
	clear: both;
}
.mb-show {
	display: none;
}
/*공통요소*/
.text-right {
	text-align: right !important;
}
.text-left {
	text-align: left !important;
}
.text-center {
	text-align: center !important;
}
.pull-right {
	float: right;
}
.pull-left {
	float: left;
}
.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
}
.ellipsis-multi {
	overflow: hidden;
	text-overflow: ellipsis; /*display:relative;*/
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	line-height: 1.2;
}
.ellipsis-multi:after {
	display: inline-block;
	position: absolute;
	right: 0;
	bottom: 20px;
	content: "\00b7\00b7\00b7";
	padding-left: 8px;
	width: 28px;
	font-size: 16px;
	background: #fff;
	-webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
}
/* 일립시스 라인 수  */
.line-2 {
	-webkit-line-clamp: 2; /* 라인수 */
	max-height: 69px;
}
.line-3 {
	-webkit-line-clamp: 3; /* 라인수 */
	max-height: 69px;
}

.row-w {
	margin: 0 auto;
	box-sizing: border-box;
	width: 100%; /*max-width:1200px !important;*/
	max-width: 1000px !important;
}
/*a{
-webkit-transition: all .75s cubic-bezier(.23,1,.32,1);
-moz-transition: all .75s cubic-bezier(.23,1,.32,1);
-o-transition: all .75s cubic-bezier(.23,1,.32,1);
transition: all .75s cubic-bezier(.23,1,.32,1);
}*/
.wrap-btn {
	margin-top: 20px;
	text-align: center;
}
.color-y {
	color:/* #ffce55 */ #ffba00;
}
.color-yr {
	color: #ff7e00;
}
.color-wh {
	color: #fff !important;
}
.color-bl {
	color: #000 !important;
}
.color-r {
	color: #bf0303;
}
/*.color-green{color:#3bc37e;}
.color-green{color:green;}*/
.color-grey {
	color: #8c8c8d;
}

.mr6 {
	margin-right: 6px;
}
.ml6 {
	margin-left: 6px;
}
.mt0 {
	margin-top: 0px !important;
}
.mt10 {
	margin-top: 10px !important;
}
.mt15 {
	margin-top: 15px !important;
}
.mt20 {
	margin-top: 20px !important;
}
.mt30 {
	margin-top: 30px !important;
}
.mt34 {
	margin-top: 34px !important;
}
.mt40 {
	margin-top: 40px !important;
}
.mt60 {
	margin-top: 60px !important;
}
.mt80 {
	margin-top: 80px !important;
}
.mb0 {
	margin-bottom: 0px !important;
}
.mb10 {
	margin-bottom: 10px !important;
}
.mb20 {
	margin-bottom: 20px !important;
}
.mb30 {
	margin-bottom: 30px !important;
}
.pt0 {
	padding-top: 0px !important;
}
.pb0 {
	padding-bottom: 0px !important;
}
.pt10 {
	padding-top: 10px !important;
}
.pb10 {
	padding-bottom: 10px !important;
}
.pt15 {
	padding-top: 15px !important;
}
.pb15 {
	padding-bottom: 15px !important;
}
.pt20 {
	padding-top: 20px !important;
}
.pb20 {
	padding-bottom: 20px !important;
}
.pt30 {
	padding-top: 30px !important;
}
.pb30 {
	padding-bottom: 30px !important;
}

.ml1 {
	margin-left: 1rem !important;
}
.ml2 {
	margin-left: 2rem !important;
}
.ml3 {
	margin-left: 3rem !important;
}

.bg-l-grey {
	background: #efefef;
}
.btn-top span.fa {
	margin-top: 5px;
	width: 60px;
	height: 30px;
	line-height: 30px;
	display: block;
}
.btn-top:hover {
	background: #8a5312;
}
.btn-top span.btn-top-txt {
	display: block;
	width: 60px;
	height: 16px;
	line-height: 10px;
	font-size: 16px;
	font-size: 1rem;
	font-weight: 100;
	color: #282828;
}
.btn-toggle {
	display: none;
}

/* textarea  */
textarea,
.textarea {
	margin: 0 auto;
	padding: 10px 20px;
	width: calc(100% - 2px);
	min-height: 160px;
	line-height: 1.4;
	background: #fff;
	border: 0;
	font-size: 14px;
	font-size: 0.875rem;
	color: #404040;
	-webkit-appearance: none; /* 네이티브 외형 감추기 */
	-moz-appearance: none;
	appearance: none;
	resize: none;
}

/* button */
input[type="button"],
input[type="button" i],
button {
	/*ie 크로스브라우징*/
	display: inline-block;
	box-sizing: border-box;
	width: auto;
	margin: 0;
	padding: 9.5px 20px;
	align-items: flex-start;
	border: 0;
	vertical-align: top;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	font-weight: normal;
	text-align: center;
	cursor: default;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.btn + .btn {
	margin-left: 1rem;
}
input[type="button"].btn,
input[type="button" i].btn,
button.btn,
.input-prepend input.btn,
.btn {
	display: inline-block;
	background: #ff7d00;
	color: #fff;
	background-image: none;
	text-shadow: none;
	box-shadow: none;
	text-align: center;
	cursor: pointer;
}
input[type="button"].btn:hover,
input[type="button" i].btn:hover,
button.btn:hover,
.input-prepend input.btn:hover,
.btn:hover {
	background: #404040;
	color: #ff7d00;
	-webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
}
input[type="button"].btn-line,
input[type="button" i].btn-line,
button.btn-line,
.btn-line {
	margin-top: 20px;
	border: 3px solid #fff;
	color: #fff;
	box-sizing: border-box;
	display: inline-block;
	width: 220px;
	height: 60px;
	padding: 17px 30px;
	border: 3px solid #ff9922;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	background: #ff9922;
	background-image: none;
	text-shadow: none;
	box-shadow: none;
	cursor: pointer;
}
input[type="button"].btn-line:hover,
input[type="button" i].btn-line:hover,
button.btn-line:hover,
.input-prepend input.btn-line:hover,
.btn-line:hover {
	box-sizing: border-box;
	border: 3px solid #fff;
	-webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	font-size: 18px;
	font-weight: bold;
	transition: all 0.2s ease-in;
}

/* select */
input,
select,
input[type="submit" i],
input[type="reset" i],
input[type="file" i]::-webkit-file-upload-button {
	display: inline-block;
	box-sizing: border-box;
	width: calc(100% - 2px);
	margin: 0 auto;
	padding: 10px 20px;
	border: 0;
	vertical-align: top;
	font-size: 16px;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	align-items: flex-start;
	-webkit-appearance: none; /* 네이티브 외형 감추기 */
	-moz-appearance: none;
	appearance: none;
}
select::-ms-expand {
	display: none;
}
select {
	width: 180px;
	max-width: calc(100% - 2px);
	height: 43px;
	padding: 1em;
	border: none;
	-webkit-appearance: none; /* 네이티브 외형 감추기 */
	-moz-appearance: none;
	appearance: none;
	background: #fff url("//cdn.qijisoft.com/miracle/images/sub/select-arrow.png") no-repeat right 50%; /* 화살표 아이콘 추가 */
	font-size: 14px;
}
select:focus {
	width: 180px;
	height: 43px;
	border: none;
	-webkit-appearance: none; /* 네이티브 외형 감추기 */
	-moz-appearance: none;
	appearance: none;
	background: #fff url("//cdn.qijisoft.com/miracle/images/sub/select-arrow-on.png") no-repeat right 50%; /* 화살표 아이콘 추가 */
	color: #404040;
}
/* check, hover 스타일 설정 IE, Chrome */
select option:checked,
select option:hover,
select:focus option {
	background: #fff;
	color: #404040;
	box-shadow: 0 0 10px 100px #ff00ff inset; /*FireFox*/
}
option {
	padding: 1em;
	width: 180px;
	height: 43px;
	color: #404040;
	border-color: transparent;
	-webkit-appearance: none; /* 네이티브 외형 감추기 */
	-moz-appearance: none;
	appearance: none;
}

/* placeholder */
::placeholder {
	color: #a8a8a8 !important;
	text-align: left;
	font-size: 16px;
	font-size: 1rem;
}
:-ms-input-placeholder {
	color: #a8a8a8 !important;
	text-align: left;
	font-size: 16px;
	font-size: 1rem;
}
::-ms-input-placeholder {
	color: #a8a8a8 !important;
	text-align: left;
	font-size: 16px;
	font-size: 1rem;
}
::-moz-input-placeholder,
::-moz-textarea-placeholder {
	color: #a8a8a8;
	text-align: left;
	font-size: 16px;
	font-size: 1rem;
}
::-webkit-input-placeholder,
::-webkit-textarea-placeholder {
	color: #a8a8a8;
	text-align: left;
	font-size: 16px;
	font-size: 1rem;
}

/* checkbox*/
input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
	width: 16px;
	height: 16px;
	padding: 0;
	margin: 4px 4px 0px 0px;
	border: 1px solid #cacaca;
	background: #fafafa;
	vertical-align: middle;
}
input[type="checkbox"]:checked,
input[type="checkbox"]:focus {
	outline: none;
}
input[type="checkbox"]:checked:after {
	position: absolute;
	content: "√";
	content: "V";
	display: inline-block;
	margin: 0 0 0 -2px;
	top: -3px;
	left: 0;
	width: 16px;
	height: 16px;
	line-height: 22px;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
	color: #6f6f6f;
	color: #404040;
}
.fa {
	text-rendering: optimizeLegibility;
}
.wrap-input .fa {
	padding-top: 9px;
	padding-left: 6px;
}

/* text password etc */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
	z-index: 10;
	padding: 8px 16px; /*height:52px;height:3.25rem;*/
	height: 40px;
	height: 2.5rem;
	border: 1px solid rgba(255, 255, 255, 0.3);
}

/* 크롬 검은 테두리 제거 */
input:not([type="checkbox"]):focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
	box-shadow: 0 0 0px 2px #ff7d00;
	/*border: 2px solid #ff7d00;*/
	/*background:#fff5d6;*/
}

/* table */
table {
	box-sizing: border-box;
	width: 100%;
	border-collapse: collapse;
}
/*table.basic thead th{border: 1px solid #bbbbbb;color:#525252;padding:16px 35px;background:#ebebeb;font-size:24px;font-size:1.5rem;}*/
table.basic tbody th {
	border: 2px solid #fff;
	color: #525252;
	padding: 16px 35px;
	background: #ebebeb;
	font-size: 18px;
	font-size: 1.125rem;
	text-align: left;
}
table.basic tbody td {
	border: 2px solid #fff;
	color: #737373;
	padding: 16px 35px;
	background: #fafafa;
	font-size: 18px;
	font-size: 1.125rem;
}

table.no-side-border thead th {
	color: #c1c1c1;
	padding: 16px 35px;
	font-size: 24px;
	font-size: 1.5rem;
	-webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
}
table.no-side-border thead th.on,
table.no-side-border thead th:hover {
	color: #373737;
	background: #ffd030;
}
table.no-side-border tbody th {
	padding: 16px 2.1875rem;
	background: #efefef;
	color: #6b6b6b;
	font-size: 20px;
	font-size: 1.25rem;
}
table.no-side-border tbody td {
	padding: 16px 2.1875rem;
	background: #fafafa;
	color: #6b6b6b;
	font-size: 14px;
	font-size: 0.875rem;
}
table.no-side-border tbody td span {
	font-size: 14px;
	font-size: 0.875rem;
}
table.no-side-border tbody td a {
	font-size: 14px;
	font-size: 0.875rem;
}
table.no-side-border tr:first-child th,
table.no-side-border tr:first-child td {
	border-top: 2px solid #878787;
	border-bottom: 2px solid #fff;
}
/*table.no-side-border thead th:first-child,
table.no-side-border tbody th:first-child,
table.no-side-border tbody td:first-child{border-left:none;}*/
table.no-side-border thead th,
table.no-side-border tbody th,
table.no-side-border tbody td {
	border: none;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
}

/* paging */
.paging {
	display: block;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 50px;
}
.paging ul {
	display: inline-block;
	width: auto;
	margin: 0 auto;
}
.paging ul li {
	float: left;
	margin-left: 10px;
}
.paging ul li:first-child {
	margin-left: 0px;
}
.paging ul li a {
	box-sizing: border-box;
	display: inline-block;
	width: 31px;
	height: 31px;
	line-height: 29px;
	text-align: center;
	border: 1px solid #ddd;
}
.paging ul li a .fa {
	vertical-align: middle;
	width: 31px;
	height: 31px;
	line-height: 31px;
	font-size: 15px;
	padding: 0;
	margin: 0 0 0 -1px;
	background: #dddddd;
	color: #656565;
	-moz-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}
.paging ul li a .svg-inline--fa {
	margin-top: -17px;
	vertical-align: middle;
}
.paging ul li a.num {
	color: #656565;
	background: #fff;
	-moz-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

.paging ul li.on a.num,
.paging ul li.on a .fa,
.paging ul li a:hover,
.paging ul li a:hover .fa {
	color: #000;
	text-align: center;
	border: 1px solid #ddd;
	background: #f39a00;
}

/*레이아웃*/
#content {
	position: relative;
	margin-top: 60px;
	width: 100%;
}
section:first-child {
	margin-top: 0;
}
#m-menu-wrap {
	display: none;
}
#bg-layer {
	display: none;
}
.bg-layer-mb {
	display: none;
	position: absolute;
	z-index: 9998;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
}

/* util 메뉴 */
select.sel {
	width: 120px;
	height: 28px;
	padding: 7px 12px 7px 0;
	font-size: 10px;
	color: #fff;
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #323338 url("//www.valofe.com/static/images/common/sel-small-arrow.png") no-repeat right 50%; /* 화살표 아이콘 추가 */
}
select.sel:focus {
	font-size: 10px;
	color: #fff;
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #323338 url("//www.valofe.com/static/images/common/sel-small-arrow-on.png") no-repeat right 50%; /* 화살표 아이콘 추가 */
}

/* footer */
#footer {
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-top: 0px;
	padding-top: 24px;
	padding-bottom: 24px;
	text-align: center;
	background-color: #1a1e24;
	color: #696868;
	letter-spacing: 1px;
}
#footer .row-w {
	text-align: center;
}
#footer .footer-logo {
	display: inline-block;
	vertical-align: top; /*width:134px;height:45px;*/
	width: 120px;
	height: 35px;
	margin-top: 0rem;
}
#footer .footer-logo a {
	display: inline-block;
	width: 100%;
	height: 100%;
	line-height: 35px;
	text-align: right;
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	background: url(//cdn.qijisoft.com/miracle/images/logo-white.png) no-repeat center left / auto 100%;
}
#footer .footer-logo .mb-show {
	display: none;
}
#footer .vfun-logo {
	position: absolute;
	display: inline-block;
	right: 0;
	top: 0;
	vertical-align: top;
	width: 90px;
	height: 30px;
	margin-top: 0;
}

#footer .copyright {
	display: inline-block;
	float: left;
	position: relative;
	padding-top: 9px;
	padding-left: 32px;
	width: calc(100% - 120px);
	vertical-align: top;
}
#footer #footerMenu {
	text-align: left;
	overflow: hidden;
	margin-bottom: 16px;
}
#footer #footerMenu li {
	width: auto;
	float: left;
}
#footer #footerMenu li a {
	display: inline-block;
	color: #858789;
	font-size: 12px;
	font-family: "Malgungothic", "맑은고딕", sans-serif;
	letter-spacing: -0.5px;
}
#footer #footerMenu li a:hover {
	color: #5ec8bd;
}
#footer #footerMenu li:before {
	content: "";
	display: inline-block;
	vertical-align: bottom;
	width: 1px;
	height: 12px;
	margin-left: 10px;
	margin-right: 8px;
	background: #858789;
}
#footer #footerMenu li:first-child:before {
	display: none;
}
#footer #footerMenu li a.privacy {
	color: #fff;
	font-weight: bold;
}
#footer #footerMenu li a.active {
	color: #fff;
	font-weight: bold;
}

#footer #bizInfo {
	display: none;
	/*font-family:'Malgungothic', '맑은고딕', sans-serif;*/
	font-style: normal;
	font-size: 12px;
	text-align: left;
	color: #858789;
	line-height: 1.5;
	letter-spacing: -0.5px;
}
#footer #bizInfo.ko {
	display: block;
}
#footer #bizInfo.ko a {
	font-size: 12px;
	font-weight: normal;
	color: #858789;
	text-decoration: underline;
}
#footer #bizInfo.ko a:hover {
	color: #5ec8bd;
}
#footer #bizInfo.en {
	display: block;
}
#footer #bizInfo.jp {
	display: block;
}
#footer #bizInfo.tw {
	display: block;
}

#footer .copyright p {
	width: 100%;
	display: block;
	margin-top: 10px;
	font-family: "Malgungothic", "맑은고딕", sans-serif;
	text-align: left;
	font-size: 12px;
	line-height: 1.4;
	color: #858789;
}

#btn-top {
	z-index: 9999;
	display: inline-block;
	position: fixed;
	right: -80px;
	top: 100px;
	width: 72px;
	height: 237px;
}
#btn-top ul li {
	float: none;
	width: 72px;
}
#btn-top ul li a {
	display: block;
	width: 72px;
	height: 59px;
	text-align: center;
	color: #fff;
	text-indent: -9999px;
}
#btn-top ul li.go-yt a {
	width: 72px;
	height: 83px;
	background: url(//www.valofe.com/static/images/common/btn-yt.png) center top no-repeat;
}
#btn-top ul li.go-top a {
	width: 72px;
	background: url(//www.valofe.com/static/images/common/btn-top.png) center top no-repeat;
}
#btn-top ul li.go-bottom a {
	width: 72px;
	background: url(//www.valofe.com/static/images/common/btn-bottom.png) center top no-repeat;
}

/* ./ 레이어 팝업 */
@media screen and (max-width: 1200px) {
	/*rem 비율 조정 13px = rem;*/
	html {
		font-size: 13px;
	}
	.row-w {
		max-width: 94% !important;
	}
	#cbm-aside {
		font-size: 13px;
	}
}
@media screen and (max-width: 1024px) {
	.row-w {
		max-width: 90% !important;
	}
	section:last-child {
		padding: 80px 0;
	}

	#mb-sel {
		width: 100%;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	#mb-sel li {
		position: relative;
		display: inline-block;
		width: 100%;
		float: none;
		padding: 10px 18px 10px 22px;
	}
	.mb-sel-cate {
		display: inline-block;
		/*height: 39px;*/
		line-height: 33px;
		font-weight: 300;
		font-size: 13px;
		color:#fff;
	}
	select.mb-sel-lang {
		position: relative;
		display: inline-block;
		border: 1px solid #676767;
		width: 145px;
		max-width: calc(100% - 2px);
		height: 33px;
		padding: 0.8em;
		border: none;
		outline: none;
		-webkit-appearance: none;
		appearance: none;
		background: #fff url(//cdn.qijisoft.com/miracle/images/sub/select-arrow.png) no-repeat right 50%;
		font-size: 12px;
	}

	select.mb-sel-lang .sel-tit{
		display: block;
		box-sizing: border-box;
		width: 120px;
		height: 37px;
		padding: 7px 10px 6px 10px;
		line-height: 22px;
		text-align: left;
		cursor: pointer;
		text-decoration: none;
		background: #616267 url(//www.valofe.com/static/images/mb/sel-arrow.png) right center no-repeat;
		color: #fff;
		cursor: pointer; outline: none!important;
	}
	select.mb-sel-lang .sel-tit:focus {
		  display: block;
		  box-sizing: border-box;
		  width: 120px !important;
		  height: 37px !important;
		  padding: 7px 10px 6px 10px !important;
		  line-height: 22px !important;
		  outline: none !important;
		  text-align: left;
		  cursor: pointer;
		  text-decoration: none;
		  background: #616267 url(//www.valofe.com/static/images/mb/sel-arrow.png) right center no-repeat;
		  color: #fff;
		  cursor: pointer;
	  }
	select.mb-sel-lang:focus{
		outline: none;
		box-shadow: none;
	}
	/*.mb-sel-lang .view {
		z-index: 9999;
		box-sizing: border-box;
		position: absolute;
		top: 37px;
		left: -1px;
		width: 122px;
		height: auto;
		padding: 7px 10px 6px 10px;
		border: 1px solid #676767;
		background: #616267;
	}
	.mb-sel-lang .view li {
		padding: 7px 0px !important;
	}
	.mb-sel-lang .sel-tit a,
	.sel-lang .view li a {
		display: inline-block;
		width: 118px;
		height: 20px;
		text-align: left;
		color: #fff;
	}
	.mb-sel-lang .sel-tit a:hover,
	.sel-lang .view li a:hover {
		text-decoration: none;
		color: #ff9922;
	}*/
	.flag-tw {
		display: inline-block;
		margin-right: 6px;
		width: 18px;
		height: 12px;
		vertical-align: middle;
		/*background: url(https://image.valofe.com/vfun/images/cbm/flag-tw.gif) 0 0 no-repeat;*/
		display: none;
	}

	.mb-sel-platform {
		position: relative;
		display: inline-block;
		border: 1px solid #676767;
	}
	.mb-sel-platform .sel-tit {
		display: block;
		box-sizing: border-box;
		width: 120px;
		height: 37px;
		padding: 7px 10px 6px 10px;
		line-height: 22px;
		text-align: left;
		cursor: pointer;
		text-decoration: none;
		background: #616267 url(//cdn-qijisoft-com/miracle/images/common/mb/sel-arrow.png) right center no-repeat;
		color: #fff;
		cursor: pointer;
	}
	.mb-sel-platform .view {
		z-index: 9999;
		box-sizing: border-box;
		position: absolute;
		top: 37px;
		left: -1px;
		width: 122px;
		height: auto;
		padding: 7px 0px 6px 10px;
		border: 1px solid #676767;
		background: #616267;
	}
	.mb-sel-platform .view li {
		padding: 7px 0px !important;
	}
	.mb-sel-platform .sel-tit a,
	.sel-lang .view li a {
		display: inline-block;
		width: 118px;
		height: 20px;
		text-align: left;
		color: #fff;
	}
	.mb-sel-platform .sel-tit a:hover,
	.sel-lang .view li a:hover {
		text-decoration: none;
		color: #ff9922;
	}

	/* #mobileHeader{z-index:8800;position:relative;float:right;display:inline-block;box-sizing:border-box;width:calc(100% - 100px);padding-right:16px;height:60px;border-top:1px solid #232323;background: rgba(19,19,19,1);color: #fff;} */
	/* #mobileHeader #crtMenu .fa-sort-up{margin-left:4px;font-size: 16px;vertical-align:middle;}
	#mobileHeader #crtMenu .fa-sort-down{margin-left:4px;padding-top: 19px;font-size: 16px;vertical-align: top;} */

	/*로컬 메뉴   */
	.lnb li a {
		padding: 0.875rem 0rem;
		font-size: 1rem;
	}
	.blit-scroll {
		height: 36px;
		width: 30px;
		top: 3px;
	}
	.blit-scroll:before {
		margin-top: 4px;
	}
	.wrap-search {
		padding-top: inherit;
	}
	.wrap-search input[type="search"] {
		margin-bottom: inherit;
		bottom: inherit;
		top: 4px;
		height: 30px;
	}
	.wrap-search button[type="button"],
	.wrap-search button[type="button"]:visited {
		width: 32px;
		height: 32px;
	}

	/*모바일 로컬메뉴 - 서브 메뉴*/
	#mbSub,
	#mbSearch {
		display: block;
		z-index: 9000;
		position: absolute;
		opacity: 0;
		visibility: hidden;
		box-sizing: border-box;
		top: 60px;
		left: 0;
		width: 100%;
		padding: 30px 16px 40px;
		background: #fff;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
		-webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
		-moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
		-o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
		transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
	}

	#mbSearch {
		background: #36373c;
	}
	#mbSub.on,
	#mbSearch.on {
		overflow-y: auto;
		height: auto;
		opacity: 1;
		visibility: visible;
		letter-spacing: -1px;
	}
	#mbSub h1 {
		font-size: 18px;
		font-weight: bold;
		color: #000;
		padding-bottom: 10px;
		margin-bottom: 15px;
		border-bottom: 3px solid #3f3f3f;
	}
	#mbSub ul {
		width: 100%;
	}
	#mbSub ul > li {
		box-sizing: border-box;
		float: none;
		width: 100%;
		padding: 10px 0 5px;
	}
	#mbSub ul > li > a,
	#mbSub > ul > li:visited > a {
		font-size: 16px;
		font-weight: bold;
		color: #3f3f3f;
	}
	#mbSub ul > li.on > a,
	#mbSub ul > li:hover > a,
	#mbSub > ul > li:focus > a {
		color: #f39a00;
		font-weight: bold;
	}

	#mbSub .dep2 {
		box-sizing: border-box;
		width: 100%;
		padding: 10px 20px 15px;
		margin-top: 10px;
		background: #fcf3ee;
	}
	#mbSub .dep2 > li > a,
	#mbSub .dep2 > li > a:visited,
	#mbSub .dep2 > li > a > .fa {
		font-size: 15px;
		font-size: 0.9375rem;
		color: #000;
	}
	#mbSub .dep2 > li.on > a,
	#mbSub .dep2 > li > a:hover,
	#mbSub .dep2 > li > a:focus,
	#mbSub .dep2 > li.on .fa,
	#mbSub .dep2 > li:hover > .fa,
	#mbSub .dep2 > li:focus > .fa {
		color: #f39a00;
	}

	#mbSearch .wrap-mb-search {
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0;
		width: 100%;
		height: auto;
	}
	#mbSearch input[type="search"] {
		position: relative;
		right: inherit;
		top: inherit;
		box-sizing: border-box;
		min-width: inherit;
		width: calc(100% - 40px);
		outline: none;
		height: 40px;
		padding: 0px 0px 0px 16px;
		margin: 0;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		font-size: 1rem;
		line-height: 40px;
		font-weight: bold;
		color: #000;
		background: #fff;
	}
	#mbSearch #btnMbSch {
		position: absolute;
		box-sizing: border-box;
		right: 0;
		top: 0;
		margin: 0;
		min-width: inherit !important;
		outline: none;
		border: none;
		width: 40px;
		height: 40px;
		padding: 5px 16px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		color: #3f3f3f;
		font-size: 1.5rem;
		background: #fff;
	}
	#mbSearch #btnMbSch:visited {
		color: #3f3f3f;
	}
	#mbSearch #btnMbSch:hover,
	#mbSearch #btnMbSch:focus {
		color: #f39a00;
		background: #fff;
	}
	#mbMenuShadow,
	#mbSchShadow {
		display: none;
		opacity: 0;
		z-index: 8000 !important;
		width: 100%;
		height: 100%;
		position: fixed;
		padding-bottom: 0px;
		top: 0;
		left: 0;
		background-size: cover;
		background: rgba(0, 0, 0, 0.8);
	}
	#mbMenuShadow.on,
	#mbSchShadow.on {
		display: block;
		opacity: 1;
		z-index: 8000 !important;
		width: 100%;
		height: 100%;
		position: fixed;
		padding-bottom: 0px;
		top: 0;
		left: 0;
		background-size: cover;
		background: rgba(0, 0, 0, 0.8);
	}

	/* 유저 정보 */
	.side-menu > .user-info {
		box-sizing: border-box;
		width: 100%;
		padding: 0px 22px;
		border-bottom: 1px solid #1a1a1e;
		background: #1f1f24;
	}
	.side-menu > .user-info.login > ul {
		display: inline-block;
		width: 100%;
		padding: 0px 0px 0px 0px;
		box-sizing: border-box;
	}
	.side-menu > .user-info.notlogin > ul {
		display: inline-block;
		width: auto;
		padding: 0;
	}
	.user-info > .user-name {
		padding: 10px 0px;
		border-bottom: 1px solid #1a1a1e;
		color: #fff;
		font-size: 14px;
	}
	.side-menu > .user-info > ul.pull-right {
		float: none;
	}
	.side-menu > .user-info > ul > li {
		position: relative;
		float: left;
		box-sizing: border-box;
		padding: 13px 0px;
		text-align: center;
	}
	.side-menu > .user-info > ul > li:first-child {
		text-align: left;
		width: 30%;
	}
	.side-menu > .user-info > ul > li:nth-child(2) {
		width: 40%;
	}
	.side-menu > .user-info > ul > li:last-child {
		text-align: right;
		width: 30%;
	}
	.side-menu > .user-info > ul > li > a {
		/* padding:18px 4px; */
	}
	.side-menu > .user-info > ul > li:hover > a {
		text-decoration: none;
		color: #ff9922;
	}
	.side-menu > .user-info > ul > li:after {
		content: "";
		position: absolute;
		display: inline-block;
		width: 2px;
		height: 14px;
		left: 0;
		top: 14px;
		/*background: url(//www.valofe.com/static/images/mb/bar-cbm.png) 0 center no-repeat;*/
	}
	.side-menu > .user-info > ul > li:first-child:after {
		display: none;
	}

	.cbm-sel-lang {
		position: relative;
		display: inline-block;
		width: 36px;
		padding-left: 10px;
	}
	.cbm-sel-lang .sel-tit {
		/* padding-right:26px; */

	}
	.cbm-sel-lang .view {
		z-index: 9999;
		position: absolute;
		padding-bottom: 10px;
		top: 24px;
		right: -22px;
		height: auto;
		background: #343333;
	}
	.cbm-sel-lang .view li {
		padding: 16px 23px 16px 30px;
	}
	.cbm-sel-lang .sel-tit a,
	.cbm-sel-lang .sel-lang .view li a {
		display: inline-block;
		height: 20px;
		text-align: left;
		color: #fff;
	}
	.cbm-sel-lang .sel-tit a:hover,
	.cbm-sel-lang .sel-lang .view li a:hover {
		text-decoration: none;
		color: #ff9922;
	}

	.cbm-sel-lang .flag {
		padding-right: 10px;
	}
	.cbm-sel-lang .flag-ko {
		box-sizing: border-box;
		display: inline-block;
		padding-right: 10px;
		width: 28px;
		height: 12px;
		vertical-align: middle;
		/*background: url(//www.valofe.com/static/images/mb/flag-kr.gif) 0 0 no-repeat;*/
	}
	.cbm-sel-lang .flag-en {
		box-sizing: border-box;
		display: inline-block;
		padding-right: 10px;
		width: 28px;
		height: 12px;
		vertical-align: middle;

	}
	.cbm-sel-lang .flag-de {
		box-sizing: border-box;
		display: inline-block;
		padding-right: 10px;
		width: 28px;
		height: 12px;
		vertical-align: middle;
		/*background: url(//www.valofe.com/static/images/mb/de.png) 0 0 no-repeat;*/
	}
	.cbm-sel-lang .flag-tr {
		box-sizing: border-box;
		display: inline-block;
		padding-right: 10px;
		width: 28px;
		height: 12px;
		vertical-align: middle;
		/*background: url(//www.valofe.com/static/images/mb/tr.png) 0 0 no-repeat;*/
	}
	.cbm-sel-lang .flag-tw {
		box-sizing: border-box;
		display: inline-block;
		padding-right: 10px;
		width: 28px;
		height: 12px;
		vertical-align: middle;
		/*background: url(//www.valofe.com/static/images/mb/flag-tw.gif) 0 0 no-repeat;*/
	}
	.cbm-sel-lang .flag-vi {
		box-sizing: border-box;
		display: inline-block;
		padding-right: 10px;
		width: 28px;
		height: 12px;
		vertical-align: middle;
	}

	/* 고객센터 */
	.side-menu > .customer {
		overflow: hidden;
		width: 100%;
		border-bottom: 1px solid #1a1a1e;
		background: #1f1f24;
	}
	.side-menu > .customer ul {
		box-sizing: border-box;
		width: 100%;
		padding: 0px 22px;
		overflow: hidden;
	}
	.side-menu > .customer ul li {
		float: left;
		box-sizing: border-box;
		width: 33%;
		padding: 13px 0px;
		text-align: center;
		position: relative;
	}
	.side-menu > .customer ul li:first-child {
		text-align: left;
		width: 30%;
	}
	.side-menu > .customer ul li:nth-child(2) {
		text-align: center;
		width: 40%;
	}
	.side-menu > .customer ul li:last-child {
		text-align: right;
		width: 30%;
	}
	.side-menu > .customer ul li:after {
		content: "";
		position: absolute;
		display: inline-block;
		width: 2px;
		height: 14px;
		left: 0;
		top: 14px;
		/*background: url(//www.valofe.com/static/images/mb/bar-cbm.png) 0 center no-repeat;*/
	}
	.side-menu > .customer ul li:first-child:after {
		display: none;
	}
	.side-menu > .customer ul li a {
		display: block;
	}
	.side-menu > .customer ul li:hover a {
		text-decoration: none;
		color: #ff9922;
	}

	/* 메뉴 리슽트  */
	/* .side-menu > .game-list,  */
	.side-menu > .nav-list {
		width: 100%;
	}
	/* .side-menu > .game-list > ul, */
	.side-menu > .nav-list > ul {
		width: 100%;
		overflow: hidden;
	}

	/* 대메뉴 리스트 */
	.nav-li {
		width: 100%;
	}
	.nav-li > a {
		box-sizing: border-box;
		position: relative;
		display: block;
		width: 100%;
		padding: 7.5px 22px;
		line-height: 24px;
		/*border-bottom:1px solid #efefef; background:url(//www.valofe.com/static/images/mb/bg-mb-list.png) left center repeat-y;*/
		border-left: 3px solid #ec7820;
		color: #fff;
	}

	/* 아이콘  */
	.nav-li > a > .icon {
		display: inline-block;
		margin-right: 7px;
		text-align: center;
		vertical-align: middle;
		margin-top: 0px;
		width: 22px;
		height: 24px;
		font-size: 22px;
		color: #fff;
	}
	.nav-li > a:hover > .icon,
	.nav-li.on > a > .icon {
		display: inline-block;
		margin-right: 7px;
		vertical-align: middle;
		width: 22px;
		height: 24px;
		/* font-size: 24px; */
		color: #fff;
	}

	.nav-li > a > .icon.fa-newspaper-o {
		margin-right: 14px;
	}

	.nav-li.on > a,
	.nav-li > a:hover,
	.nav-li > a:focus {
		text-decoration: none;
		background: #ec7820;
		border-left: 3px solid #1a1e30;
		color: #fff !important;
	}

	.nav-li > a:visited,
	.nav-li > a:active {
		/*border-left:3px solid #0f1c33;*/
	}

	.nav-li > a > .size {
		display: inline-block;
		position: absolute;
		right: 25px;
		top: 12.5px;
		margin-right: 17px;
		box-sizing: border-box;
		width: 26px;
		height: 15px;
		line-height: 12px;
		font-size: 9px;
		padding-top: 2px;
		border-radius: 8px;
		vertical-align: middle;
		text-align: center;
		background: #ec7820;
		color: #fff;
	}

	.nav-li > a:hover > .size,
	.nav-li.on > a > .size {
		background: #fff;
		color: #ff9922;
	}

	.nav-li > a > .blit-arrow,
	.nav-li > a:visited > .blit-arrow {
		display: inline-block;
		position: absolute;
		right: 16px;
		top: 16px;
		box-sizing: border-box;
		/* width: 9px;
		height: 8px; */
		vertical-align: middle;
		border-left: 6px solid transparent;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-right: 6px solid #2c2f3d;
		transition: 0.5s;
		-o-transition: 0.5s;
		-webkit-transition: 0.5s;
		-moz-transition: 0.5s;
		/* background: url(//www.valofe.com/static/images/mb/blit-mb-list.png) 0 0 no-repeat; */
	}

	.nav-li > a:hover > .blit-arrow,
	.nav-li.on > a > .blit-arrow.on {
		text-decoration: none;
		border-right-color: #fff;
		transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		/* background: url(//www.valofe.com/static/images/mb/blit-mb-list-on.png) 0 0 no-repeat; */
	}

	/*.nav-li > ul{display:none;}*/
	.nav-li > ul.on {
		display: block;
	}
	.nav-li > li > a {
		box-sizing: border-box;
		display: block;
		width: 100%;
		padding: 11px 22px;
		border-bottom: 1px solid #1a1a1e;
	}

	.nav-li > ul > li > a {
		position: relative;
		box-sizing: border-box;
		display: block;
		line-height: 42px;
		width: 100%;
		padding: 0px 58px;
		/*border-bottom:1px solid #efefef;*/
		background: #2c2f3d;
		color: #9d9d9d;
	}
	.nav-li > ul > li > a > img {
		display: inline-block;
		margin-top: 4px;
		margin-right: 12px;
		width: 34px;
		height: 34px;
		border-radius: 17px;
	}

	.nav-li > ul > li.on > a,
	.nav-li > ul > li.on > a > .fa,
	.nav-li > ul > li > a:hover,
	.nav-li > ul > li > a:focus {
		text-decoration: none;
		color: #fff;
		background: #424656;
	}

	.nav-li > ul > li.on > a .blit-game-arrow,
	.nav-li > ul > li:hover > a .blit-game-arrow {
		display: inline-block;
		position: absolute;
		right: 16px;
		top: 21px;
		box-sizing: border-box;
		width: 9px;
		height: 8px;
		vertical-align: middle;
		text-decoration: none;
		background: url(//www.valofe.com/static/images/mb/blit-cbm-game-on.png) 0 0 no-repeat;
	}


	#footer #footerMenu {
		display: inline-block;
		margin-top: 16px;
	}
	#footer #bizInfo {
		padding-top: 0px;
		margin-top: 0;
		text-align: center;
	}
	#footer .footer-logo {
		position: relative;
		float: none;
	}
	#footer .footer-logo .mb-show {
		display: none;
	}

	#footer .copyright {
		display: block;
		float: none;
		position: relative;
		padding-top: 12.5px;
		padding-left: 0px;
		width: 96%;
		margin-left: auto;
		margin-right: auto;
		vertical-align: top;
	}
	#footer .copyright p {
		position: relative;
		padding-bottom: 6px;
		text-align: center;
	}
	#footer .vfun-logo {
		display: block;
		position: relative;
		right: inherit;
		margin: 8.5px auto 0;
		top: inherit;
		text-align: center;
	}
	/* 라인 */
	#footer .footer-logo:after {
		content: "";
		margin-top: 10px;
		display: block;
		width: 100%;
		height: 1px;
		background: #000;
	}
	#footer #bizInfo:after {
		content: "";
		display: block;
		width: 100%;
		margin-top: 10px;
		height: 1px;
		background: #000;
	}
	#footer .copyright p:after {
		content: "";
		display: block;
		width: 100%;
		margin-top: 10px;
		height: 1px;
		background: #000;
	}
	/* 회사소개 : 회사소개 : 회사정보 */
	table.basic tbody th {
		padding: 16px;
	}
	table.basic tbody td {
		padding: 16px;
	}
	/*회사소개 : 연혁 */
	table.no-side-border thead th {
		padding: 16px;
		font-size: 1.25rem;
	}
	table.no-side-border tbody th {
		padding: 16px;
	}
	table.no-side-border tbody td {
		padding: 16px;
	}
}
/* 960 */
@media screen and (max-width: 960px) {
}

/*800 */
@media screen and (max-width: 800px) {
}
/* 768 736*/
@media screen and (max-width: 768px) {
	.row-w {
		max-width: 90% !important;
	}
	.title h1 {
		line-height: 2.5rem;
	}
	.title h2 {
		font-size: 14px;
	}
}
/*  667 640 */
@media screen and (max-width: 640px) {
	.mb-show {
		display: block;
	}
}
/* 568 533 */
@media screen and (max-width: 568px) {
	html {
		font-size: 12px;
	}
	input[type="button"].btn-line,
	input[type="button" i].btn-line,
	button.btn-line,
	.input-prepend input.btn-line,
	.btn-line {
		margin-top: 20px;
		width: 180px;
		height: 40px;
		padding: 10px 30px 7px;
		font-size: 14px;
	}

	table.no-side-border thead th,
	table.no-side-border tbody th,
	table.no-side-border tbody td {
		padding: 16px 10px;
	}
	table.no-side-border tbody td,
	table.no-side-border tbody td span,
	table.no-side-border tbody td a {
		font-size: 12px;
	}
	table.no-side-border tbody th {
		padding: 16px 8px;
	}
}
/* 533 */
@media screen and (max-width: 533px) {
}
/* 480 */
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 460px) {
	#m-menu-wrap #m-menu li ul li.flag {
		float: left;
		width: 30px;
		height: 30px;
		margin-right: 10%;
	}
}
@media screen and (max-width: 414px) {
}
@media screen and (max-width: 360px) {
}
@media screen and (max-width: 320px) {
	html {
		min-width: 320px;
	}
}

.wrap-loading {
	/*화면 전체를 어둡게 합니다.*/
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #262626; /*not in ie */
	opacity: 0.9;
	z-index: 9999;
}
.wrap-loading div {
	/*로딩 이미지*/
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -330px;
	margin-top: -220px;
}
.display-none {
	/*감추기*/
	display: none;
}
